.footer {
  background-color: #282c34;
}

.container {
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  gap: 1.7rem;
  padding: 1rem;
}
