.about {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content {
  max-width: 90ch;
  text-align: start;
  padding: 0 1.5rem;
}
