.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
}

.menu_wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  gap: 2rem;
  max-width: calc((300px * 3) + (2rem * 4));
  padding: 0 2rem;
}

.heightMax {
  height: 100%;
}