.header {
  height: 500px;
  display: flex;
  flex-direction: column;
  color: white;
  align-items: flex-start;
  padding: 1rem;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-left: 7%;
  height: 100%;
  gap: 1.7rem;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.content p {
  max-width: 50ch;
  white-space: pre-wrap;
  text-align: start;
}