.location {
  background-color: rgba(0, 0, 0, 0.70);
  position: relative;
}

.container {
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  gap: 1.7rem;
  padding: 1rem;
}

.wrapper {
  width: 80%;
  margin: 20px 0 0 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.background {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  z-index: -1;
}

.contact {
  color: #636363;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.infos {
  display: flex;
  flex-direction: column;
  min-width: 405px;
}

.header {
  height: 100%;
  display: flex;
  flex-direction: row;
}

.iconSep {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30px;
}

.content {
  margin-left: 25px;
  margin-bottom: 35px;
  text-align: start;
}

.content p b {
  font-weight: 500;
}

.sep {
  width: 2px;
  height: 100%;
  margin: 6px 0;
  background-color: white;
}

.mapContainer {
  width: 100%;
  max-width: 800px;
  height: 514px;
  max-height: calc(100vh - 80px);
  margin-left: 5vw;
  background-color: #e5e4e0;
  border-radius: 3px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.icon {
  width: 20px;
  height: auto;
  padding: 0.3rem 0;
}

@media only screen and (max-width: 1350px) {
  .wrapper {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .mapContainer {
    margin-left: 0;
    width: 100%;
  }

  .wrapper {
    gap: 4rem;
  }

  .infos {
    min-width: 358px;
  }
}

@media only screen and (max-width: 540px) {
  .infos {
    min-width: 288px;
  }

  .content p {
    font-size: 1.4em !important;
  }
}
