.wrapper {
  background-color: rgba(0, 0, 0, 0.65);
  position: relative;
}

.img {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  z-index: -1;
  filter: blur(5px);
}

.loading {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: rgb(40, 44, 52, 0.56);
  z-index: -2;
}
