.gallery {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  gap: 1.5rem;
  max-width: calc((300px * 3) + (1.5rem * 4));
  padding: 0 1.5rem;
}

.item {
  width: 300px;
  height: 225px;
  display: block;
}

.item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup_inner {
  overflow: auto;
  position: relative;
}

.arrows {
  position: absolute;
  font-size: 3rem;
  display: flex;
  justify-content: space-between;
  width: 100%;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  padding: 0.5rem;
  color: rgb(236, 236, 236);
}

.arrows * {
  height: 3rem;
  width: 3rem;
  padding: 0.2rem;
  border-radius: 50%;
  /* box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75); */
  cursor: pointer;
}

.arrows button {
  all: unset;
}

/* .popup_inner img {
  animation-name: fadeIn;
  animation-duration: 0.2s;
  animation-fill-mode: both;
} */

.quit {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0.2rem;
  z-index: 99;
  cursor: pointer;
}

.container button {
  all: unset;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .arrows * {
    height: 1.5rem;
    width: 1.5rem;
    padding: 0.2rem;
  }
}

  @-webkit-keyframes fadeIn {
  0% {opacity: 0;}
  100% {opacity: 1;}
  }
  @keyframes fadeIn {
  0% {opacity: 0;}
  100% {opacity: 1;}
  } 