.wrapper {
  display: flex;
  width: 100%;
  justify-content: center;
  z-index: 100;
  background-color: white;
}

.container {
  min-width: 300px;
  padding: 1rem;
}
.infosContainer {
  width: 100%;
  text-align: left;
}